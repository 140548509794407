// current delay for photo is 5000 ms
$photo-ticker-delay: 0.5s;

.custom-photo-ticker {
  z-index: 1;
  min-height: 52vh;
  width: 100%;
  background-color: $yellow;
  overflow: hidden;
  display: relative;
  .photo-button {
    &.prev-b {
      padding-top: 0.2rem;
      transform: rotateY(180deg);
    }
    padding: 1rem 5rem;
    z-index: 2;
    cursor: pointer;
    position: relative;
    color: $white;
    text-decoration: none;
    border: none;
    background: none;
    opacity: 1;
    svg {
      transform: scale(3, 3);
      path {
        color: black;
        &:hover {
          color: white;
          &:hover {
            opacity: 0.7;
            animation-play-state: paused;
          }
        }
      }
    }
  }
  &-photo-container {
    position: relative;

    .custom-photo-ticker-photo {
      &:hover {
        cursor: pointer;
      }
      img {
        display: none;
        background: none;
        &:active {
          cursor: pointer;
          opacity: 0.5;
        }

        // styling for center photo on tablet/desktop and only photo for mobile
        &.active-photo {
          position: relative;
          top: 0;
          left: auto;
          max-height: 50vh;
          box-sizing: border-box;
          display: inline-block;
          padding: 1rem 1rem;
          opacity: 1;
          transition: opacity 0.75s ease-out;
          &:hover {
            cursor: pointer;
            opacity: 0.8;
            animation: bounce 0.68s ease infinite;
          }

          @include for-mobile {
            right: 0;
            padding: 0;
            max-height: 100%;
            max-width: 100%;
          }
        }

        // styling photos on left and right
        &.next-photo,
        &.prev-photo {
          position: absolute;
          right: auto;
          display: block;
          opacity: 0.5;
          max-height: 20vh;
          max-width: 20vw;
          &:hover {
            cursor: pointer;
            opacity: 0.8;
            // animation: bounce 0.7s ease infinite;
          }
          @include for-mobile {
            display: none;
          }
        }
        &.next-photo {
          top: 0;
          right: 10%;
          animation: next-photo $photo-ticker-delay forwards;
        }
        &.prev-photo {
          left: 10%;
          animation: prev-photo 0.75s ease-out forwards;
        }
      }
    }
  }
}
