/* Animations 
*/
.fade-in {
  -webkit-animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes rotate-90-cw {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(90deg);
  }
}
@keyframes rotate-180-cw {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(180deg);
  }
}
@keyframes rotate-90-ccw {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-90deg);
  }
}

@keyframes rotate-180-ccw {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-180deg);
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    transform: rotateX(10deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

@keyframes marquee-right {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}

@keyframes marquee-left {
  0% {
    left: 30%;
  }
  100% {
    left: -100%;
  }
}
@keyframes prev-photo {
  0% {
    transform: translateX(200%);
  }
  50% {
    transform: translate(-75%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes next-photo {
  0% {
    transform: translateX(200%);
  }
  50% {
    transform: translate(-30%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes active-photo {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translate(30%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes spin-fingers {
  0% {
    transform: rotateZ(0deg);
  }
  50% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

@keyframes open-accordion {
  0% {
    transform: scale(1, -1);
  }
  33% {
    transform: scale(1, -0.1);
  }
  67% {
    transform: scale(1, -0.01);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes close-accordion {
  0% {
    transform: scale(1, 1);
  }
  33% {
    transform: scale(1, -0.37);
  }
  67% {
    transform: scale(1, -0.67);
  }
  100% {
    transform: scale(0, -1);
  }
}
