.App-header {
  .header-art {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }
  .header-title {
    position: relative;
    max-width: 23vw;
    margin: 0;
    padding: 0;
  }
  .header-subtitle {
    position: relative;
    margin: 0;
    padding: 0;
    font-family: $font1;
    font-weight: 300;
    font-size: 1rem;
    @include for-mobile {
      top: -0.8rem;
      font-size: 0.8rem;
    }
    @include for-tablet {
      top: -1.2rem;
    }
    @include for-desktop-up {
      top: -2.1rem;
    }
  }
}
