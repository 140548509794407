.cas-square-icon-container {
  z-index: 2;
  position: relative;
  height: 4rem;
  width: 4rem;
  margin: 0;
  padding: 0;
  &.clicked-square {
    .cas-square {
      animation: rotate-180-ccw 0.5s ease both;
    }
  }
}
.MuiSvgIcon-root {
  &.cas-arrow {
    z-index: 2;
    cursor: pointer;
    position: relative;
    width: 30px;
    height: 30px;
    top: 30%;
  }
}

@mixin active-arrow($id) {
  #cas-arrow-#{$id} {
    transition-property: rotate;
    transition-delay: 0.2s;
    transition-timing-function: cubic-bezier(1, 0.25, 0.5, 0.25);
    &.active-drawer {
      rotate: 90deg;
      // box-shadow: inset 0.2rem 0.2rem;
    }
  }
}

@include active-arrow(0);
@include active-arrow(1);
@include active-arrow(2);
@include active-arrow(3);
@include active-arrow(4);
