$pc: white;
$sc: black;
$bg: none;
$border-colors: (
  1: blue,
  2: orange,
  3: purple,
  4: cyan,
);
$first_value: default;
$second_value: default;

@use "sass:map";
@use "sass:math";
.comment-wall {
  text-transform: uppercase;
  .marquee-wrapper {
    text-align: start;
    white-space: nowrap;
    animation-fill-mode: backwards;
    &:nth-of-type(even) {
      background: $yellow;
      color: $sc;
      border-color: $sc;
    }
    .container {
      overflow: hidden;
    }
    .marquee-inner {
      display: block;
      width: 200%;
      position: absolute;
      &-span {
        float: left;
        width: 50%;
      }
    }
    .marquee-block {
      // margin: 20px auto;
      height: 5rem;
      --total-marquee-items: 5;
      width: calc(1080px * (var(--total-marquee-items)));
      overflow: hidden;
      box-sizing: border-box;
      position: relative;
    }
    .marquee-item {
      width: 900px;
      margin: 0 10px;
      height: auto;
      display: inline-block;
      transition: all 0.5s ease-in;
      line-height: 1.6rem;
      height: auto;
      .comment-style-div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow: hidden;
        // align-items: baseline;
        .comment-row-comment-top {
          font-size: 2rem;
          list-style: none;
          height: 3rem;
          padding: 0.5rem 1rem;
          width: 100%;
          border-bottom: 0.2rem solid;
          border-left: 0.4rem solid;
        }
        .comment-row-comment-bottom {
          padding: 0 1rem;
          border-left: 0.4rem solid;
        }
      }
    }
  }
}
@mixin marquee-mixin($name, $id, $start, $end) {
  @keyframes #{$name} {
    0% {
      left: calc(#{$start});
    }
    100% {
      left: calc(#{$end});
    }
  }
}

@include marquee-mixin(slide-1, 1, 7%, -80%);
@include marquee-mixin(slide-2, 2, 7%, -80%);
@include marquee-mixin(slide-3, 3, 7%, -90%);
@include marquee-mixin(slide-4, 4, 7%, -90%);

.comment-wall {
  animation-play-state: running;
  .marquee-wrapper {
    @for $i from 1 through 4 {
      border: 1px solid;
      &:nth-of-type(#{$i}) {
        .marquee-inner {
          animation: slide-#{$i} 24s linear infinite;
          animation-play-state: running;
          animation-fill-mode: both;
          animation-delay: calc(#{$i - 1 * math.random($limit: 500) * 0.001s});
        }
      }
    }
  }
}

@include for-tablet {
  @include marquee-mixin(slide-1, 1, 15%, -93%);
  @include marquee-mixin(slide-2, 2, 15%, -93%);
  @include marquee-mixin(slide-3, 3, 15%, -93%);
  @include marquee-mixin(slide-4, 4, 15%, -93%);
}

@include for-desktop-up {
  @include marquee-mixin(slide-1, 1, 40%, -83%);
  @include marquee-mixin(slide-2, 2, 40%, -83%);
  @include marquee-mixin(slide-3, 3, 40%, -83%);
  @include marquee-mixin(slide-4, 4, 40%, -83%);
}
