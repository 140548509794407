/* Accordion */
@mixin square-icon-styling($id, $top-or-bottom, $left-or-right) {
  // .MuiSvgIcon-root .cas-square-#{$id} {
  .MuiSvgIcon-root {
    &.cas-square-#{$id} {
      position: absolute;
      width: 15px;
      height: 15px;
      z-index: 0;
      #{$top-or-bottom}: 0;
      #{$left-or-right}: 0;
    }
  }
}
$tertiary: $yellow;
$background-def: $tertiary;
.custom-accordion {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  animation-play-state: running;

  &-section {
    text-transform: uppercase;
    background: $background-def;
    color: $secondary;
    &:nth-child(even) {
      background: none;
      color: $primary;
    }
    &:nth-child(3) {
      background: none;
      .cas-visible-content {
        .cas-card {
          background: $background-def;
        }
        .cas-bottom-row {
          // bottom padding for subcategories
          &:last-child {
            padding-bottom: 0.5rem;
          }
        }
      }
    }
    .cas-visible-content {
      .cas-card {
        height: 100%;
        cursor: pointer;
        background-color: inherit;
        .cas-top-row {
          z-index: 1;
          margin: 0;
          padding-top: 0.5rem;
          border-bottom: 0.2rem solid;
          display: flex;
          justify-content: center;
          align-content: flex-end;
          height: 5rem;
          .cas-title-text {
            align-self: center;
            font-family: $font1;
            font-size: 5rem;
            font-weight: 150;
            margin: 0;
            padding: 0;
            padding-right: 1rem;
          }
        }
        .cas-bottom-row {
          margin: 0;
          padding: 0;
          font-size: 1rem;
          .cas-subcategories {
            display: flex;
            flex-direction: row;
            align-content: center;
            justify-content: center;
          }
        }
      }
      .cas-section-content {
        animation: open-accordion 0.7s;
        animation-timing-function: cubic-bezier(0, 0.8, 0, 1);
      }
    }
    // SHOWS bottom-padding
    &:last-child {
      padding-bottom: 1rem;
    }
  }
}

@include square-icon-styling(0, top, left);
@include square-icon-styling(1, top, right);
@include square-icon-styling(2, bottom, left);
@include square-icon-styling(3, bottom, right);
