#modal-div {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  max-width: 100%;
  height: 500px;
  max-height: 100%;
  @include for-mobile {
    // height/width
    width: 100vw;
    height: 100vh;
  }
  @include for-tablet {
    // height/width
    width: 100vw;
    height: 80vh;
  }
  @include for-desktop-up {
    width: 100%;
    height: 100%;
  }

  &.active-modal {
    z-index: 2;
  }
  .photo-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    &-img {
    }
    &-close-button {
      background-color: $yellow;
      padding: 0.5rem;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
    }
  }
  &.hidden {
    display: none;
  }
}
