// variables

$font1: "Source Sans Pro", sans-serif;
$font2: "Staatliches", sans-serif;
$font3: "Roboto", sans-serif;

$yellow: #fedc71;
$white: #fff9e6;
$primary: white;
$secondary: black;

$media-breakpoint-sm: 768px;
$media-breakpoint-md: 992px;
$media-breakpoint-lg: 1200px;

@mixin for-mobile {
  @media screen and (max-width: $media-breakpoint-sm) {
    @content;
  }
}

@mixin for-tablet {
  @media screen and (min-width: $media-breakpoint-sm) and (max-width: $media-breakpoint-md) {
    @content;
  }
}

@mixin for-desktop-up {
  @media screen and (min-width: $media-breakpoint-md) {
    @content;
  }
}

@mixin background-url {
  background: url("https://elephantetix.imgix.net/assets/bg.svg?compress=auto&format=auto&width=1000&height=1000&expires=1679699848&s=8012170ed50675321a26fa26ef12bb75");
}

.svg-filter-for-yellow {
  filter: invert(77%) sepia(42%) saturate(475%) hue-rotate(356deg)
    brightness(105%) contrast(104%);
}

.App {
  @include background-url();
  /* resets */
  font-family: $font1;
  color: #fff9e6;
  text-align: center;
  line-height: 1.5;
  letter-spacing: 0.5px;
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
  text-shadow: none;
  text-decoration: none;
  text-indent: 0;
  height: 100%;
}

.header-container {
  position: relative;
  @include background-url();
  width: 100%;
  z-index: 1;
}

.spacer-div {
  width: 100%;
}

.errorPageContainer {
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    padding: 0;
    margin: 0;
    margin-bottom: 1rem;
    border-top: 2rem solid $yellow;
    border-bottom: 2rem solid $yellow;
    font-family: $font2;
    display: block;
    font-size: 3rem;
    color: white;
  }
  @include background-url();
  img {
    &:hover {
      cursor: pointer;
    }
  }
}

.login-page {
  @include background-url();
  min-height: 100vh;
  width: 100%;
  text-align: left;
  display: flex;
  .App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100%;
    margin: 0;
    .header-title {
      padding-bottom: 10px;
    }
  }
  flex-direction: column;
  &-title {
    margin: 0;
    padding-left: 1rem;
    padding-bottom: 1rem;
    text-align: left;
    font-family: $font2;
    font-size: 3rem;
    color: white;
  }
  &-form {
    margin: 0 1rem;
    overflow: hidden;
    background: black;
    color: white;
    &-text-input {
      font-family: $font1;
      width: 100%;
      border: none;
      font-size: 2rem;
      padding: 0.5rem;
      text-decoration: none;
    }
    &-submit-button {
      font-size: 2rem;
      font-family: $font2;
      margin-top: 1rem;
      height: 50px;
      background-color: $yellow;
      width: 100%;
      &:hover {
        cursor: pointer;
        color: white;
      }
      &:active {
        cursor: pointer;
        color: white;
        opacity: 0.8;
      }
    }
    margin-bottom: 20px;
  }
  &-phlogo {
    align-self: center;
    width: auto;
    max-height: 250px;
    border-top: 2rem solid $yellow;
    border-bottom: 2rem solid $yellow;
    &:hover {
      cursor: pointer;
      opacity: 0.95;
    }
    &:active {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  @include for-tablet() {
    &-title {
      align-self: center;
      text-align: center;
    }
    &-form {
      align-self: center;
      max-width: 700px;
      width: 70%;
    }
  }

  @include for-desktop-up() {
    &-title {
      align-self: center;
      text-align: center;
    }
    &-form {
      align-self: center;
      max-width: 700px;
      width: 70%;
    }
  }
}
/* 
Colors and styles
*/

.grid-container {
  max-width: 100%;
  min-height: 100%;
}

/* Component styles */

@import "./styles/index.scss";

/* Animation import */
@import "./files/animations.scss";
